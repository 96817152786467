import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify, {
  iconfont: "md",
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#90A4AE",
        success: "#0D47A1",
        secondary: "#ECEFF1",
        accent: "#B71C1C",
        info: "#6085B2",
        error: "#F83E70",
      },
    },
  },
});