import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "./custom.css";

Vue.use(CKEditor);
Vue.use(VueAxios, axios);
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
