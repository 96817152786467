// store.js
import Vue from "vue";

export default Vue.observable({
    photos_search: "",
    photos_fld: "",
    archives_search: "",
    archives_fld: "",
    types_search: "",
    types_fld: "",
    stories_search: "",
    stories_fld: "",
    places_search: "",
    places_fld: "",
    durl: 'em1'
});


