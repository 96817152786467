import Vue from "vue";
import VueRouter from "vue-router";

const Pimages = () => import("../views/Pimages.vue");
const Photos = () => import("../views/Photos.vue");
const EditJournal = () => import("../views/EditJournal.vue");
const Types = () => import("../views/Types.vue");
const Stats = () => import("../views/Stats.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Photos",
    component: Photos,
    props: { tid: "photos" }
  },
  {
    path: "/Pimages",
    name: "Images",
    component: Pimages,
    props: { tid: "images" }
  },
  {
    path: "/EditJournal",
    name: "EditJournal",
    component: EditJournal,
    props: { tid: "journal" }
  },
  {
    path: "/Types",
    name: "Types",
    component: Types,
    props: { tid: "types" }
  },
  {
    path: "/Stats",
    name: "Stats",
    component: Stats,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
