<template>
  <nav>
    <v-app-bar
      text
      class="secondary"
      app
    >
      <v-app-bar-nav-icon
        x-large
        color="success"
        class="ml-5"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase accent--text">
        <span class="black--text font-weight-bold">{{ mainTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="accent white--text mr-5"
        href="http://localhost/gee/"
      >Home</v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      class="secondary pt-4"
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-5"
          justify="center"
          align="center"
        >
          <v-avatar size="150">
            <img
              src="https://vsgpartners.com/images/birdlogo.jpg"
              alt
            />
          </v-avatar>
        </v-col>
      </v-row>
      <v-list>
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon class="black--text ml-4 pb-4">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="black--text">
              {{ link.text }}
              <br />
              <br />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  props: ["mainTitle"],
  data() {
    return {
      drawer: true,
      links: [
        { icon: "mdi-camera", text: "Photos", route: "/" },
        { icon: "mdi-image-album", text: "Images", route: "/Pimages" },
        { icon: "mdi-bird", text: "Types", route: "/Types" },
        { icon: "portrait", text: "Journal", route: "/EditJournal" },
        { icon: "mdi-data-matrix", text: "Stats", route: "/Stats" },
      ],
    };
  },
};
</script>
