import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c(VAppBar,{staticClass:"secondary",attrs:{"text":"","app":""}},[_c(VAppBarNavIcon,{staticClass:"ml-5",attrs:{"x-large":"","color":"success"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,{staticClass:"text-uppercase accent--text"},[_c('span',{staticClass:"black--text font-weight-bold"},[_vm._v(_vm._s(_vm.mainTitle))])]),_c(VSpacer),_c(VBtn,{staticClass:"accent white--text mr-5",attrs:{"href":"http://localhost/gee/"}},[_vm._v("Home")])],1),_c(VNavigationDrawer,{staticClass:"secondary pt-4",attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VRow,[_c(VCol,{staticClass:"mt-5",attrs:{"cols":"12","justify":"center","align":"center"}},[_c(VAvatar,{attrs:{"size":"150"}},[_c('img',{attrs:{"src":"https://vsgpartners.com/images/birdlogo.jpg","alt":""}})])],1)],1),_c(VList,_vm._l((_vm.links),function(link){return _c(VListItem,{key:link.text,attrs:{"router":"","to":link.route}},[_c(VListItemAction,[_c(VIcon,{staticClass:"black--text ml-4 pb-4"},[_vm._v(_vm._s(link.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"black--text"},[_vm._v(" "+_vm._s(link.text)+" "),_c('br'),_c('br')])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }