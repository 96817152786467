<template>
  <v-app>
    <Navbar :mainTitle="mainTitle" />
    <v-main class="primary">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import store from "@/store/store.js";

export default {
  name: "App",
  components: { Navbar },
  data() {
    return {
      start: [],
      store: [],
      mainTitle: "Photography",
      url: "http://localhost/em1/data.php",
    };
  },
  async created() {
    var url = this.url;
    var db = store.durl;
    var formdata = {
      action: "search-g",
      tid: "startup",
      db: db,
    };
    this.axios
      .post(url, formdata, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then((res) => {
        const data = res.data;
        this.start = data;
        store.photos_search = data[0].search;
        store.photos_fld = data[0].fld;
        store.archives_search = data[1].search;
        store.archives_fld = data[1].fld;
        store.types_search = data[2].search;
        store.types_fld = data[2].fld;
        store.stories_search = data[3].search;
        store.stories_fld = data[3].fld;
        store.places_search = data[4].search;
        store.places_fld = data[4].fld;
        store.durl = data[5].search;
      })
      .catch((error) => alert(error));
  },
};
</script>

<style lang="css">
html {
  width: 100%;
}

body {
  width: 100%;
}

.v-progress-circular {
  margin: 0.5rem !important;
}

.v-progress-circular__underlay {
  stroke: #90a4ae !important;
  z-index: 1 !important;
}

.tablediv {
  overflow: auto;
}

.numbers {
  text-align: right;
}

tr:nth-child(even) {
  background-color: #cfd8dc;
}

p,
li {
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 150%;
}
p {
  clear: both;
}
.numbers {
  text-align: right !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
  border: 1px solid #ddd;
}

th,
td {
  padding: 5px 10px 5px 10px;
  text-align: left;
  cursor: pointer;
}

th {
  cursor: pointer;
  text-align: left;
}

th.title:hover {
  color: orange !important;
}

th,
.searchbar {
  cursor: pointer;
}

td:hover {
  white-space: normal;
  width: auto;
}

tr:hover td,
.searchbar:hover {
  background-color: orange;
}

.item-title,
.title {
  font-family: "Roboto Slab", serif;
  font-size: 1.3rem !important;
  line-height: 1.5;
  letter-spacing: 1px;
  line-height: 28px;
  font-weight: 500;
  color: #330000;
  text-align: left;
}

.v-date-picker-table {
  height: 100% !important;
  max-height: 300px !important;
}

.ck-editor__editable {
  min-height: 500px;
}

.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

.custom-block-indent-a {
  margin-left: 10%;
}

.custom-block-indent-b {
  margin-left: 20%;
}

.custom-block-indent-c {
  margin-left: 30%;
}
.side-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
.passform {
  border-style: groove;
  border: 2px solid red;
  height: 40px;
  cursor: pointer;
  padding-left: 10px;
}
p {
  clear: left !important;
}
h1,
h2,
h3,
.title {
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 1px;
  line-height: 160%;
}
p,
li {
  font-family: "Roboto Slab", serif;
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 150%;
}

li {
  margin-bottom: 10px;
}

a {
  color: blue !important;
}

@media only screen and (max-width: 600px) {
  img,
  figure {
    max-width: 250px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  img,
  figure {
    max-width: 350px !important;
  }
}
</style>
